/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ContentPageTemplate from "../../templates/content-page";
const MDXLayout = ContentPageTemplate;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Psychoanalyse"), "\n", React.createElement(_components.p, null, "Die ", React.createElement(_components.strong, null, "Psychoanalyse"), ", deren Grundlagen von Sigmund Freud und anderen erarbeitet und die seitdem immer weiterentwickelt wurde, erfolgt in der Regel hochfrequent mit mehreren Terminen pro Woche. Sie arbeitet stark mit der Beziehung zwischen Therapeut /in und Patient /-in. Es entwickelt sich eine sehr intensive Behandlungsbeziehung mit vielfältigen Übertragungsphänomenen, innerhalb derer eine Entwicklung des Patienten / der Patientin angestoßen werden soll."), "\n", React.createElement(_components.p, null, "Die Psychoanalyse arbeitet im Vergleich zu anderen Behandlungsverfahren mit am stärksten an der Persönlichkeit des Patienten / der Patientin, gilt gleichzeitig als besonders aufwändig und zeitintensiv. Zuweilen sind mehrere hundert Behandlungssitzungen erforderlich."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
